<template>
  <v-dialog
    v-model="dialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-card
        outlined
        color="grey lighten-4"
        :class="noBottomSpacing ? '' : 'mb-2'"
        v-bind="attrs"
        v-on="on"
        style="cursor: pointer"
      >
        <v-card-text
          class="pt-4"
          :style="noBottomSpacing ? 'padding-bottom: 0px' : ''"
        >
          <v-row>
            <v-col style="padding-right: 0px">
              <span class="title" style="padding-right: 1rem"
                >#{{ bookingProp.refNo }}</span
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="shrink" style="padding-left: 0px">
              <v-chip
                v-if="bookingProp.isPaid"
                :color="bookingProp.isPaidStripe ? '#F79E1B' : 'green'"
                :title="
                  bookingProp.isPaidStripe
                    ? 'Paid in USD though Stripe payment gateway'
                    : undefined
                "
                >Paid
                <v-icon
                  v-if="bookingProp.isPaidStripe"
                  style="margin-left: 0.3rem"
                  >mdi-credit-card-outline</v-icon
                ></v-chip
              >
              <v-chip v-if="bookingProp.isAllowed" color="cyan"
                >Allowed{{
                  bookingProp.allowedType
                    ? `
                              (${bookingProp.allowedType})`
                    : ""
                }}</v-chip
              >
              <v-chip
                v-if="
                  bookingProp.isCashAllowed &&
                  !bookingProp.isPaid &&
                  !bookingProp.isAllowed
                "
                color="pink lighten-3"
                >Cash On Arrival</v-chip
              >
              <div
                v-if="
                  !bookingProp.isPaid &&
                  !bookingProp.isAllowed &&
                  !bookingProp.isCashAllowed
                "
              >
                <v-chip
                  v-if="
                    bookingProp.transfer.id !== 0 &&
                    bookingProp.transfer.status === 'Pending'
                  "
                  color="orange"
                  >Pending Verification</v-chip
                >
                <v-chip
                  v-else-if="
                    bookingProp.cashPayment.id !== 0 &&
                    bookingProp.cashPayment.status === 'Pending'
                  "
                  color="orange"
                  >Pending Cash Option</v-chip
                >
                <v-chip v-else-if="bookingProp.isCancelled" color="white"
                  >Cancelled</v-chip
                >
                <v-chip v-else>Pending</v-chip>
              </div>
            </v-col>
          </v-row>
          <v-row style="margin-bottom: 10px">
            <v-col style="padding-top: 0px; padding-bottom: 0px">
              <div
                v-if="pickupPoint || dropoffPoint"
                style="margin-bottom: 0.5rem"
              >
                <div class="subtitle-3" v-if="pickupPoint">
                  Pickup from <strong>{{ pickupPoint.name }}</strong>
                </div>
                <div class="subtitle-3" v-if="dropoffPoint">
                  Dropoff to <strong>{{ dropoffPoint.name }}</strong>
                </div>
              </div>
              <div v-if="bookingProp.routeToStop">
                <span class="subtitle-1">{{
                  bookingProp.routeToStop.routeStop.location.name
                }}</span>
                <v-icon class="mb-1">mdi-ray-start-arrow</v-icon>
                <span class="subtitle-1">{{
                  bookingProp.routeToStop.location.name
                }}</span>
              </div>
              <div class="subtitle-3" v-if="bookingProp.email">
                <a :href="`mailto:${bookingProp.email}`">{{
                  bookingProp.email
                }}</a>
              </div>
              <div class="subtitle-3" v-if="bookingProp.phone">
                <a :href="`tel:${bookingProp.phone}`">{{
                  bookingProp.phone
                }}</a>
              </div>
              <div class="subtitle-3" v-if="seatConfig && seatNames()">
                Seats: {{ seatNames() }}
              </div>
            </v-col>
          </v-row>
          <v-row
            style="margin-bottom: 0px"
            v-if="tripCustomers.length > 0 && hideCustomers === false"
          >
            <v-col style="padding-top: 0px; padding-bottom: 0px">
              <div
                class="subtitle-3"
                v-for="customer in tripCustomers"
                :key="customer.id"
                style="display: flex"
              >
                <v-icon v-if="customer.boarded">mdi-check</v-icon>
                <span v-else style="margin-right: 24px"></span>
                <div
                  style="display: flex; justify-content: space-between; flex: 1"
                >
                  <div>
                    {{ customer.name }}
                  </div>
                  <div>
                    {{ customer.nid }}
                  </div>
                  <div>
                    {{ customer.emergencyContact }}
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <v-card>
      <v-progress-linear v-if="loading" indeterminate color="primary" />
      <v-card-text
        v-if="booking"
        style="padding-top: 16px; padding-bottom: 30px"
      >
        <v-row style="margin-bottom: 0px">
          <v-spacer></v-spacer>
          <v-col class="shrink" style="padding-right: 0px; padding-left: 0px">
            <v-chip
              v-if="booking.isPaid"
              :color="booking.isPaidStripe ? '#F79E1B' : 'green'"
              :title="
                booking.isPaidStripe
                  ? 'Paid in USD though Stripe payment gateway'
                  : undefined
              "
              >Paid
              <v-icon v-if="booking.isPaidStripe" style="margin-left: 0.3rem"
                >mdi-credit-card-outline</v-icon
              ></v-chip
            >
            <v-chip v-if="booking.isAllowed" color="cyan"
              >Allowed{{
                booking.allowedType ? ` (${booking.allowedType})` : ""
              }}</v-chip
            >
            <v-chip
              v-if="
                booking.isCashAllowed && !booking.isPaid && !booking.isAllowed
              "
              color="pink lighten-3"
              >Cash On Arrival</v-chip
            >
            <div
              v-if="
                !booking.isPaid && !booking.isAllowed && !booking.isCashAllowed
              "
            >
              <v-chip
                v-if="
                  booking.transfer.id !== 0 &&
                  booking.transfer.status === 'Pending'
                "
                color="orange"
                >Pending Verification</v-chip
              >
              <v-chip
                v-else-if="
                  booking.cashPayment.id !== 0 &&
                  booking.cashPayment.status === 'Pending'
                "
                color="orange"
                >Pending Cash Option</v-chip
              >
              <v-chip v-else-if="bookingProp.isCancelled" color="white"
                >Cancelled</v-chip
              >
              <v-chip v-else>Pending</v-chip>
            </div>
          </v-col>
          <v-col class="shrink" v-if="actionItems.length > 0">
            <v-menu left rounded="lg" close-on-content-click close-on-click>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-if="
                    actionItems.filter((action) =>
                      [
                        'ProceedTransfer',
                        'Allow Cash Payment',
                        'Reject Cash Payment',
                      ].includes(action)
                    ).length > 0
                  "
                >
                  <v-badge
                    bordered
                    color="red"
                    icon="mdi-exclamation-thick"
                    overlap
                  >
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      icon
                      v-on="on"
                      :loading="
                        (cancellingAllow &&
                          cancellingBookingId === booking.id) ||
                        (proceedingCashPayment &&
                          proceedingCashPaymentId === booking.id)
                      "
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </v-badge>
                </div>
                <div v-else>
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                    :loading="
                      (cancellingAllow && cancellingBookingId === booking.id) ||
                      (proceedingCashPayment &&
                        proceedingCashPaymentId === booking.id)
                    "
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </div>
              </template>
              <v-list>
                <v-list-item v-if="actionItems.includes('View Payment Slip')">
                  <v-list-item-title>
                    <a
                      style="
                        text-decoration: none !important;
                        color: rgb(33 33 33);
                      "
                      :href="getImageUrl(booking)"
                      target="_blank"
                      >View Payment Slip</a
                    >
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="actionItems.includes('ProceedTransfer')">
                  <ProceedTransfer
                    :booking="booking"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getBookings="getBookings"
                    :getBooking="getBooking"
                    :getBookingsList="getBookingsList"
                  />
                </v-list-item>
                <v-list-item v-if="actionItems.includes('ConfirmCash')">
                  <ConfirmCash
                    :captain="false"
                    :booking="booking"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getBooking="getBooking"
                  />
                </v-list-item>
                <v-list-item v-if="actionItems.includes('Allow Cash Payment')">
                  <v-list-item-title
                    @click="proceedCashPayment(booking, 'Allowed')"
                    style="cursor: pointer"
                  >
                    <v-icon color="red" class="mb-1" style="margin-left: -11px"
                      >mdi-exclamation</v-icon
                    >Allow Cash Payment
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="actionItems.includes('Reject Cash Payment')">
                  <v-list-item-title
                    @click="proceedCashPayment(booking, 'Rejected')"
                    style="cursor: pointer"
                  >
                    <v-icon color="red" class="mb-1" style="margin-left: -11px"
                      >mdi-exclamation</v-icon
                    >Reject Cash Payment
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="actionItems.includes('AllowBooking')">
                  <AllowBooking
                    :booking="booking"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getTrips="getTrips"
                    :getBookings="getBookings"
                    :getBooking="getBooking"
                    :getBookingsList="getBookingsList"
                  />
                </v-list-item>
                <v-list-item v-if="actionItems.includes('Cancel Allow')">
                  <v-list-item-title
                    @click="cancelAllowBooking(booking)"
                    style="cursor: pointer"
                    >Cancel Allow
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="actionItems.includes('CancelBooking')">
                  <CancelBooking
                    :booking="booking"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getTrips="getTrips"
                    :getBookings="getBookings"
                    :getBooking="getBooking"
                    :getBookingsList="getBookingsList"
                  />
                </v-list-item>
                <BankTransfer
                  v-if="actionItems.includes('BankTransfer')"
                  :message="message"
                  :errorMessage="errorMessage"
                  :booking="booking"
                  :getBooking="getBooking"
                  :inList="true"
                />
                <v-list-item v-if="actionItems.includes('SetAsCash')">
                  <SetAsCash
                    :booking="booking"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getTrips="getTrips"
                    :getBookings="getBookings"
                    :getBooking="getBooking"
                    :getBookingsList="getBookingsList"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row style="margin-left: 0px; margin-right: 0px; margin-top: 0px">
          <v-card width="100%" elevation="0" outlined>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Booking number</v-list-item-title>
                <v-list-item-subtitle class="text-wrap"
                  >#{{ booking.refNo }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row style="margin-left: 0px; margin-right: 0px; margin-top: 20px">
          <v-card width="100%" elevation="0" outlined>
            <v-row style="margin: 12px 4px 12px 16px">
              <v-col style="padding: 0px">
                <div class="text-subtitle-1">
                  <ToFromLocation
                    :from="
                      bookingProp?.trip?.fromLocation ??
                      booking?.trip?.fromLocation
                    "
                    :to="
                      bookingProp?.trip?.toLocation ?? booking?.trip?.toLocation
                    "
                    :pickupPoint="booking.pickupPoint"
                    :dropoffPoint="booking.dropoffPoint"
                  />
                </div>
                <div class="text-body-2" style="color: rgba(0, 0, 0, 0.6)">
                  {{ computeDate(booking.trip.fromTime) }}
                </div>
                <div class="text-body-2" style="color: rgba(0, 0, 0, 0.6)">
                  {{ toFromString(booking.trip) }}
                </div>
                <div
                  class="text-body-2"
                  style="color: rgba(0, 0, 0, 0.6)"
                  v-if="seatConfig && seatNames(booking.trip)"
                >
                  Seats: {{ seatNames(booking.trip) }}
                  <RemoveSeats
                    v-if="$store.getters.getUser.isAdmin"
                    :booking="booking"
                    :trip="booking.trip"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getBooking="getBookings"
                    :getTripsParent="getTrips"
                  />
                </div>
              </v-col>
              <v-col
                class="shrink"
                style="padding: 0px"
                v-if="$store.getters.getUser.isAdmin"
              >
                <div v-if="!booking.trip.deleted_at">
                  <a
                    :href="goToTrip(booking.trip)"
                    style="text-decoration: none"
                  >
                    <v-btn color="primary" small text style="padding: 5px">
                      <v-icon class="mr-0"
                        >mdi-arrow-right-circle-outline</v-icon
                      >View
                    </v-btn>
                  </a>
                </div>
                <div
                  v-if="
                    !booking.trip.departed &&
                    !booking.trip.ended &&
                    !booking.routeToStopId
                  "
                >
                  <ChangeBookingByAdmin
                    :booking="booking"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getBooking="getBookings"
                    :selectedTrip="booking.trip"
                    :getTripsParent="getTrips"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="booking.returnTripId"
              style="margin: 0px 4px 12px 16px"
            >
              <v-col style="padding: 0px">
                <div class="text-subtitle-1">
                  <ToFromLocation
                    :from="
                      bookingProp?.trip?.fromLocation ??
                      booking.returnTrip.fromLocation
                    "
                    :to="
                      bookingProp?.trip?.toLocation ??
                      booking.returnTrip.toLocation
                    "
                    :pickupPoint="booking.dropoffPoint"
                    :dropoffPoint="booking.pickupPoint"
                  />
                </div>
                <div class="text-body-2" style="color: rgba(0, 0, 0, 0.6)">
                  {{ computeDate(booking.returnTrip.fromTime) }}
                </div>
                <div class="text-body-2" style="color: rgba(0, 0, 0, 0.6)">
                  {{ toFromString(booking.returnTrip) }}
                </div>
                <div
                  class="text-body-2"
                  style="color: rgba(0, 0, 0, 0.6)"
                  v-if="seatConfig && seatNames(booking.returnTrip)"
                >
                  Seats: {{ seatNames(booking.returnTrip) }}
                  <RemoveSeats
                    v-if="$store.getters.getUser.isAdmin"
                    :booking="booking"
                    :trip="booking.returnTrip"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getBooking="getBookings"
                    :getTripsParent="getTrips"
                  />
                </div>
              </v-col>
              <v-col class="shrink" style="padding: 0px">
                <div v-if="!booking.returnTrip.deleted_at">
                  <a
                    :href="goToTrip(booking.returnTrip)"
                    style="text-decoration: none"
                  >
                    <v-btn color="primary" small text style="padding: 5px">
                      <v-icon class="mr-0"
                        >mdi-arrow-right-circle-outline</v-icon
                      >View
                    </v-btn>
                  </a>
                </div>
                <div
                  v-if="
                    !booking.returnTrip.departed &&
                    !booking.returnTrip.ended &&
                    !booking.routeToStopId
                  "
                >
                  <ChangeBookingByAdmin
                    :booking="booking"
                    :message="message"
                    :errorMessage="errorMessage"
                    :getBooking="getBookings"
                    :selectedTrip="booking.returnTrip"
                    :getTripsParent="getTrips"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-row style="margin-left: 0px; margin-right: 0px; margin-top: 20px">
          <v-card width="100%" elevation="0" outlined>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-wrap"
                  >Customers</v-list-item-title
                >
                <v-list-item-subtitle
                  v-for="customer in booking.customers"
                  :key="customer.id"
                >
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      {{ customer.name }}
                    </div>
                    <div>
                      {{ customer.nid }}
                    </div>
                    <div>
                      {{ customer.emergencyContact }}
                    </div>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row style="margin-left: 0px; margin-right: 0px; margin-top: 20px">
          <v-card width="100%" elevation="0" outlined>
            <v-list-item v-if="booking.email">
              <v-list-item-content>
                <v-list-item-title class="text-wrap"
                  >Email address</v-list-item-title
                >
                <v-list-item-subtitle>
                  <a :href="`mailto:${booking.email}`">{{ booking.email }}</a>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="booking.phone">
              <v-list-item-content>
                <v-list-item-title class="text-wrap"
                  >Phone number</v-list-item-title
                >
                <v-list-item-subtitle>
                  <a :href="`tel:${booking.phone}`">{{ booking.phone }}</a>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row
          v-if="booking.notes"
          style="margin-left: 0px; margin-right: 0px; margin-top: 20px"
        >
          <v-card width="100%" elevation="0" outlined>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-wrap"
                  >Other details</v-list-item-title
                >
                <v-list-item-subtitle>{{ booking.notes }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row style="margin-left: 0px; margin-right: 0px; margin-top: 20px">
          <v-card width="100%" elevation="0" outlined>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      min-width: 150px;
                    "
                  >
                    <span>Payment</span>
                    <span
                      class="text-subtitle-2"
                      style="color: rgba(0, 0, 0, 0.6); font-weight: 400"
                      v-if="
                        (!booking.isPaid && !booking.isAllowed) ||
                        booking.paidInUSD
                      "
                      >{{ decimalCurrency(booking.priceInCent) }} USD</span
                    >
                    <span
                      class="text-subtitle-2"
                      style="color: rgba(0, 0, 0, 0.6); font-weight: 400"
                      v-if="
                        (!booking.isPaid && !booking.isAllowed) ||
                        booking.paidInMVR
                      "
                      >{{ decimalCurrency(booking.priceInLaari) }} MVR</span
                    >
                    <span
                      class="text-subtitle-2"
                      style="color: rgba(0, 0, 0, 0.6); font-weight: 400"
                      v-if="booking.isAllowed && booking.allowedType === ''"
                      >Free of charge</span
                    >
                  </div>
                  <div
                    v-if="booking?.transfer?.refNo"
                    class="text-subtitle-2"
                    style="
                      color: rgba(0, 0, 0, 0.6);
                      font-weight: 400;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <div>Reference number</div>
                    <div>{{ booking.transfer.refNo }}</div>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row
          v-if="booking.actions.length > 0"
          style="margin-left: 0px; margin-right: 0px; margin-top: 20px"
        >
          <v-card width="100%" elevation="0" outlined>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">History</v-list-item-title>
                <v-list-item-subtitle
                  v-for="action in booking.actions"
                  :key="action.id"
                  style="
                    text-overflow: unset;
                    white-space: unset;
                    margin-top: 0.3rem;
                  "
                >
                  {{ getDatetime(action.created_at) }}
                  <span style="font-weight: bold">{{
                    action.user.username
                  }}</span>
                  {{ action.action }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { DT_FORMATS, configEnabled, findTripStartTime } from "../helpers";
import BankTransfer from "./BankTransfer.vue";
import AllowBooking from "./Booking/AllowBooking.vue";
import CancelBooking from "./Booking/CancelBooking.vue";
import RemoveSeats from "./Booking/RemoveSeats.vue";
import SetAsCash from "./Booking/SetAsCash.vue";
import ChangeBookingByAdmin from "./ChangeBookingByAdmin.vue";
import ToFromLocation from "./Common/ToFromLocation.vue";
import ConfirmCash from "./ConfirmCash.vue";
import ProceedTransfer from "./ProceedTransfer.vue";

export default {
  name: "BookingDialog",
  components: {
    ProceedTransfer,
    ChangeBookingByAdmin,
    ConfirmCash,
    AllowBooking,
    ToFromLocation,
    CancelBooking,
    BankTransfer,
    SetAsCash,
    RemoveSeats,
  },
  props: {
    bookingProp: Object,
    message: Function,
    errorMessage: Function,
    getBookings: Function,
    getTrips: Function,
    getBookingsList: Function,
    hideCustomers: Boolean,
    noBottomSpacing: Boolean,
    seatConfig: Boolean,
  },
  data: () => ({
    dialog: null,
    booking: null,
    loading: false,
    cancellingAllow: false,
    cancellingBookingId: -1,
    proceedingCashPayment: false,
    proceedingCashPaymentId: -1,
  }),
  computed: {
    tripCustomers() {
      let customers = [];
      const currentTab = this.$route.query.tab;
      if (!this.currentTrip && currentTab !== "trips") return customers;
      if (this.bookingProp.customers) {
        if (this.bookingProp.tripId === this.currentTrip) {
          customers = this.bookingProp.customers.map((cust) => ({
            ...cust,
            boarded: cust.hasBoarded,
          }));
        } else if (this.bookingProp.returnTripId === this.currentTrip) {
          customers = this.bookingProp.customers.map((cust) => ({
            ...cust,
            boarded: cust.hasBoardedReturn,
          }));
        }
      }
      return customers;
    },
    currentTrip() {
      return parseInt(this.$route.query.trip);
    },
    isReturnTrip() {
      if (this.bookingProp.tripId === this.currentTrip) return false;
      return true;
    },
    pickupPoint() {
      if (!this.bookingProp) return null;
      if (!this.bookingProp.pickupPoint && !this.bookingProp.dropoffPoint)
        return null;
      if (this.isReturnTrip) {
        return this.bookingProp.dropoffPoint ?? null;
      } else {
        return this.bookingProp.pickupPoint ?? null;
      }
    },
    dropoffPoint() {
      if (!this.bookingProp) return null;
      if (!this.bookingProp.pickupPoint && !this.bookingProp.dropoffPoint)
        return null;
      if (this.isReturnTrip) {
        return this.bookingProp.pickupPoint ?? null;
      } else {
        return this.bookingProp.dropoffPoint ?? null;
      }
    },
    actionItems() {
      const actions = [];
      if (!this.booking) return actions;
      if (
        this.booking.transfer.id !== 0 &&
        this.booking.transfer.status === "Verified"
      ) {
        actions.push("View Payment Slip");
      }
      if (
        this.$store.getters.getUser.isAdmin &&
        this.booking.transfer.id !== 0 &&
        this.booking.transfer.status === "Pending" &&
        !this.booking.isPaid &&
        !this.booking.isAllowed &&
        !this.booking.isCancelled
      ) {
        actions.push("ProceedTransfer");
      }
      if (
        this.booking.isCashAllowed &&
        !this.booking.isPaid &&
        !this.booking.isAllowed &&
        !this.booking.isCancelled
      ) {
        actions.push("ConfirmCash");
      }
      if (
        this.$store.getters.getUser.isAdmin &&
        this.booking.cashPayment.id !== 0 &&
        this.booking.cashPayment.status === "Pending" &&
        !this.booking.isPaid &&
        !this.booking.isAllowed &&
        !this.booking.isCancelled
      ) {
        actions.push("Allow Cash Payment");
      }
      if (
        this.$store.getters.getUser.isAdmin &&
        this.booking.cashPayment.id !== 0 &&
        this.booking.cashPayment.status === "Pending" &&
        !this.booking.isPaid &&
        !this.booking.isAllowed &&
        !this.booking.isCancelled
      ) {
        actions.push("Reject Cash Payment");
      }
      if (
        !this.booking.isPaid &&
        !this.booking.isAllowed &&
        !this.booking.isCancelled
      ) {
        actions.push("AllowBooking");
      }
      if (this.booking.isAllowed) {
        actions.push("Cancel Allow");
      }
      if (this.$store.getters.getUser.isAdmin && !this.booking.isCancelled) {
        actions.push("CancelBooking");
      }
      if (
        !this.booking.isAllowed &&
        !this.booking.isCancelled &&
        (this.booking.isCashAllowed || !this.booking.isPaid)
      ) {
        actions.push("BankTransfer");
      }
      if (
        !this.booking.isAllowed &&
        !this.booking.isCancelled &&
        (this.booking.transfer.id !== 0 || !this.booking.isPaid)
      ) {
        actions.push("SetAsCash");
      }
      return actions;
    },
  },
  methods: {
    seatNames(trip) {
      const tripId = trip ? trip.id : this.currentTrip;
      if (this.bookingProp?.seats?.length > 0) {
        const seatsOfTrip = this.bookingProp.seats.filter(
          (s) => s.tripId === tripId
        );
        return seatsOfTrip.map((seat) => seat.boatLayoutColumn.name).join(", ");
      }
      return "";
    },
    getDatetime(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    computeDate(date) {
      return moment(date).format("dddd, D MMMM YYYY");
    },
    toFromString(trip) {
      let format = DT_FORMATS.time12hr;
      if (configEnabled("Use 24 hour clock", this.$store.getters.getConfigs)) {
        format = DT_FORMATS.time24hr;
      }
      return findTripStartTime(trip, trip.routeToStop).format(format);
    },
    decimalCurrency(number) {
      const small = number.toString();
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      );
    },
    isBeforeNow(time) {
      return moment(moment()).isBefore(time);
    },
    getImageUrl(booking) {
      return `${process.env.VUE_APP_BASE_URL ?? ""}/img/paymentslip/${
        booking.uuid
      }`;
    },
    goToTrip(trip) {
      const date = moment(trip.fromTime).format("YYYY-MM-DD");
      return `/admin?date=${date}&trip=${trip.id}`;
    },
    getBooking() {
      this.loading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getbooking?bookingId=${
          this.bookingProp.id
        }`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.booking = resp.data.booking;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    proceedCashPayment(booking, status) {
      this.proceedingCashPayment = true;
      this.proceedingCashPaymentId = booking.id;
      const data = {
        bookingId: booking.id,
        status,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/proceedcashpayment`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getBookings();
          this.getTrips();
          this.getBooking();
          if (this.getBookingsList) {
            this.getBookingsList();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.proceedingCashPayment = false;
          this.proceedingCashPaymentId = -1;
        });
    },
    cancelAllowBooking(booking) {
      this.cancellingAllow = true;
      this.cancellingBookingId = booking.id;
      const data = {
        bookingId: booking.id,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/cancelallowbooking`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getBookings();
          this.getTrips();
          this.getBooking();
          if (this.getBookingsList) {
            this.getBookingsList();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.cancellingAllow = false;
          this.cancellingBookingId = -1;
        });
    },
  },
  created() {
    if (this.bookingProp.routeToStop) {
      this.bookingProp.trip.fromLocation =
        this.bookingProp.routeToStop.routeStop.location;
      this.bookingProp.trip.toLocation = this.bookingProp.routeToStop.location;
    }
  },
  watch: {
    dialog: {
      handler: function (val) {
        if (val) this.getBooking();
      },
    },
  },
};
</script>
